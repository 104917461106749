export const whitelistUrls = [/mybestbrands\.com/i];
export const ignoreErrors = [
  'Failed to fetch',
  'Non-Error promise rejection captured with value: Push notification prompting can only be done from a user gesture.',
  'Script error.',
  /^Error: ResizeObserver.*/,
  /Cannot read property 'adUnits' of undefined$/,
];

export const ignoreUrls = [
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /\/(gtm|ga|analytics)\.js/i,
  /^chrome:\/\//i,
  /cdn-a\.yieldlove\.com\/yieldlove-bidder\.js.*/i,
  /connect\.facebook\.net\/en_US\/all\.js/i,
  /eatdifferent\.com\.woopra-ns\.com/i,
  /extensions\//i,
  /graph\.facebook\.com/i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  /stats\.g\.doubleclick\.net/i,
  /webappstoolbarba\.texthelp\.com\//i,
];
