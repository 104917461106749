export const COOKIE_NAMES = {
  GENDER: '_mbb_gender',
  GENDER_DE: '_mbb_usr_gndr',
  UID: 'uid',
  SID: 'sid',
  MAX_TOUCH_POINTS: 'maxTouchPoints',
  CONSENTS: 'consent',
  SEARCH_TERM: 'search_term',
  PDP_OVERLAY_ENABLED: 'pdp_overlay_enabled',
  EH_MODE: 'eh',
  LOGGEDIN_DE: 'MBBAuth',
  PRODUCT_LOADED_FROM_SERVER: 'product_loaded_from_server',
  MBB_ATTRIBUTION: 'mbb_attribution',
  AB_TEST: 'abtest',
  AUTH_TOKEN: 'auth',
  WISHLIST_PRODUCT: 'wishlist_product',
};

export enum PAGE_TYPES {
  HOME = 'home',
  GUIDES = 'guides',
  HOME_LIVING = 'home-living',
  LIST = 'listing',
  SEARCH = 'search',
  PURCHASE = 'clickout',
  DETAILS = 'details',
  OTHER = 'other',
  PROFILE = 'profile',
}

export const GENDER = {
  MAN: 'male',
  WOMAN: 'female',
  NONE: 'none',
  NEUTRAL: 'neutral',
};

export type Gender = (typeof GENDER)[keyof typeof GENDER];

export const PRODUCTION_ENVIRONMENTS = ['dev', 'pre', 'live'];

export const LETTERS = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ#');

export const LISTING_IMAGE_SIZES = '(max-width: 600px) 90vw, (max-width: 960px) 50vw, 20vw';

export const IMAGE_LOADING =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANIAAAC0CAYAAADhNHIFAAABgUlEQVR42u3TMQ0AAAjAMFCOT06UIIKDp5WwZNkzFcBJGgmMBEYCIwFGAiOBkcBIgJHASGAkMBJgJDASGAmMBBgJjARGAiMBRgIjgZHASICRwEhgJMBIYCQwEhgJMBIYCYwERgKMBEYCI4GRACOBkcBIYCTASGAkMBIYCTASGAmMBEaSAYwERgIjAUYCI4GRwEiAkcBIYCQwEmAkMBIYCYwEGAmMBEYCIwFGAiOBkcBIgJHASGAkMJKRwEhgJDASYCQwEhgJjAQYCYwERgIjAUYCI4GRwEiAkcBIYCQwEmAkMBIYCYwEGAmMBEYCjARGAiOBkQAjgZHASGAkwEhgJDASGAkwEhgJjARGAowERgIjgZEAI4GRwEhgJMBIYCQwEmAkMBIYCYwEGAmMBEYCIwFGAiOBkcBIgJHASGAkMBJgJDASGAmMBBgJjARGAiMZCYwERgIjAUYCI4GRwEiAkcBIYCQwEmAkMBIYCYwEGAmMBEYCIwFGAiOBkcBIgJHg0wJMrDE3s0k+zQAAAABJRU5ErkJggg==';

export const INTL_SIZES_ORDER = [
  '4XS',
  'XXXXS',
  '3XS',
  'XXXS',
  'XXXS-9XL',
  '2XS',
  'XXS',
  'XXS A',
  'XXS B',
  'XXS C',
  'XXS/XS',
  'XXS-XS',
  'XXS-XS A',
  'XXS-XS B',
  'XXS-XS C',
  'XXS/34',
  'XS',
  'XS/S',
  'XS-S',
  'XS-S B',
  'XS-S C',
  'XS A',
  'XS A-C',
  'XS B',
  'XS C',
  'XS D',
  'XS-XL',
  'XS-XXL',
  'S',
  'S A',
  'S A-C',
  'S B',
  'S C',
  'S/M',
  'S-M',
  'S-M B',
  'S-M C',
  'SM',
  'S-XXL',
  'M',
  'M A-C',
  'M B',
  'M C',
  'M D',
  'M/L',
  'M-L',
  'M-L B',
  'M-L C',
  'ML',
  'L',
  'L B',
  'L C',
  'L/XL',
  'L-XL',
  'L-XL B',
  'L-XL C',
  'LXL',
  'XL',
  'XL/XXL',
  'XL-XXL',
  'XL-XXL A',
  'XL-XXL B',
  'XL-XXL C',
  'XL B',
  'XL C',
  '2XL',
  'XXL',
  'XXL/XXL',
  'XXL-XXXL',
  'XXL/3XL',
  'XXL B',
  'XXL C',
  '3XL',
  'XXXL',
  'XXXL B',
  'XXXL C',
  '4XL',
  '4XL B',
  '4XL C',
  '4XL-5XL',
  'XXXXL',
  '5XL',
  '5XL B',
  '5XL C',
  'XXXXXL',
  '6XL',
  '6XL B',
  '6XL C',
  '6XL-7XL',
  '7XL B',
  '7XL C',
  '9XL-10XL',
];

export enum RETAILER_IDS {
  PEEK_CLOPPENBURG = 1157,
}
export const ITEMS_PER_PAGE = 90;
export const MAX_PAGES = 100; // from + size must be less than or equal to: [10000] (90x110)
